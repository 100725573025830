<template>
  <b-card title="บัญชีสำหรับฝากเงิน 🙌" sm="6">
    <b-button
      type="button"
      class="btn btn-block"
      variant="primary"
      v-b-modal.modal-deposit
    >
      คลิกเพื่อดูเลขบัญชีฝากเงิน
    </b-button>
    <b-alert variant="primary" show class="mt-1">
      <div class="alert-body">
        <feather-icon icon="InfoIcon" class="mr-50" />
        ** ฝากเงินขั้นต่ำ {{ this.minimumDeposit.value }} บาท / ถอนเงินขั้นต่ำ
        {{ this.minimumWithdraw.value }} บาท ** <br />
      </div>
    </b-alert>
    <b-alert variant="warning" show class="mt-1">
      <div class="alert-body">
        <feather-icon icon="InfoIcon" class="mr-50" />
        กรุณาโอนเงินจากหมายเลขที่บัญชี {{ member ? member.bank_no : '' }}
        ที่สมัครไว้เท่านั้นเท่านั้น !!
      </div>
    </b-alert>
    <b-alert variant="danger" show class="mt-1">
      <div class="alert-body">
        <feather-icon icon="InfoIcon" class="mr-50" />
        หากลูกค้าโอนเงินแล้วยอดเงินไม่เข้าภายใน 5 นาที
        กรุณาติดต่อแอดมินได้เลยค่ะ
      </div>
    </b-alert>
    ติดต่อ ADMIN คลิก :
    <a href="https://lin.ee/5G85KBx"
      ><img
        src="https://scdn.line-apps.com/n/line_add_friends/btn/th.png"
        alt="เพิ่มเพื่อน"
        height="36"
        border="0"
    /></a>
    <!-- modal backdrop -->
    <b-modal
      id="modal-deposit"
      ok-only
      centered
      hide-header-close
      no-close-on-backdrop
      content-class="shadow"
      title="บัญชีธนาคาร"
      ok-title="เช็คยอด"
      @ok="dispatchStatementJob"
    >
      <b-media>
        <template #aside>
          <b-img
            :src="require('@/assets/images/logo/scb-logo.png')"
            blank-color="#ccc"
            width="75"
            height="75"
            alt="placeholder"
            rounded="circle"
            class="mr-2"
          />
        </template>
        <h3 class="media-heading">
          ธนาคารไทยพาณิชย์
        </h3>
        <h4 class="media-heading">
          ชื่อบัญชี {{ bank ? bank.bank_account_name : '' }}
        </h4>

        <h4>
          {{ bank ? bank.bank_account_no : '' }}
          <b-button
            @click="copyToClipboard"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            size="sm"
            class="ml-2"
          >
            คัดลอก
          </b-button>
        </h4>
      </b-media>

      <b-alert show variant="info">
        <div class="alert-body">
          กรุณาอ่าน !!!!<br />
          - กดปุ่มเช็คยอดเมื่อโอนเงินเรียบร้อยแล้วเท่านั้น
        </div>
      </b-alert>
    </b-modal>
  </b-card>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { BCard, BMedia, BImg, BButton, BAlert } from 'bootstrap-vue'
import md5 from 'md5'

import { getUserData } from '../../auth/utils'

export default {
  name: 'DepositBox',
  components: {
    BCard,
    BMedia,
    BImg,
    BButton,
    BAlert,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent
  },
  directives: {
    Ripple
  },
  data() {
    return {
      accountNo: '8162626431',
      items: [],
      user: null,
      bank: null,
      member: null,
      minimumDeposit: '',
      minimumWithdraw: '',
      transactions: [],
      disableTime: null
    }
  },
  methods: {
    async copyToClipboard() {
      const textToCopy = this.bank?.bank_account_no || ''
      try {
        await navigator.clipboard.writeText(textToCopy)
        // Show success notification
        this.onCopy()
      } catch (err) {
        // Fallback for older browsers
        console.log('err', err)
        this.copyToClipboardFallback(textToCopy)
      }
    },

    // Fallback to older execCommand-based approach.
    copyToClipboardFallback(text) {
      const input = document.createElement('input')
      input.value = text
      document.body.appendChild(input)
      input.select()
      try {
        console.log('dd', input.value)
        document.execCommand('copy')
        this.onCopy() // Success handler
      } catch (err) {
        console.error('Fallback copy failed:', err)
        this.onError(err) // Error handler
      } finally {
        document.body.removeChild(input)
      }
    },

    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'คัดลอกเลขที่บัญชีเรียบร้อยแล้ว',
          icon: 'BellIcon',
          variant: 'success'
        }
      })
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'ไม่สามารถคัดลอกข้อมูลได้',
          icon: 'BellIcon',
          variant: 'danger'
        }
      })
    },
    async dispatchStatementJob() {
      const hash = this.genHash()
      const resp = await this.$http.post('member/check-balance', {
        hash,
        memberId: this.member.id,
        user: this.member.agent_username,
        accountNo: this.bank.bank_account_no
      })

      if (resp.data.success === true) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'ระบบกำลังดำเนินการปรับยอดอัตโนมัติ',
            icon: 'EditIcon',
            variant: 'success'
          }
        })
      }
    },
    async getActiveBank() {
      const b = await this.$http.post('/member/bank/active', {
        domain: 'rizz888.com'
      })
      this.bank = b.data.bank
      this.minimumDeposit = b.data.minDeposit
      this.minimumWithdraw = b.data.minWithdraw
    },
    async getMemberInfo() {
      const member = await this.$http.get('member/info')

      if (member.status === 200) {
        return member.data
      }
      return null
    },
    genHash() {
      const currentDate = new Date() // Creates a new Date object with the current date and time
      const currentHour = currentDate.getHours()
      return md5(`${this.member.agent_username}-${currentHour}`)
    },
    transformT(txs) {
      const resp = []
      txs.forEach(element => {
        let v = 'light-warning'
        if (element.status === 'confirmed') {
          v = 'light-success'
        } else if (element.status === 'reject') {
          v = 'light-danger'
        }

        resp.push({
          Tx: element.id,
          date_time: `${element.date} ${element.time}`,
          amount: element.amount,
          status: {
            title: element.status,
            variant: v
          }
        })
      })
      return resp
    },
    async getTransactions() {
      const tx = await this.$http.get('/member/deposit/tx')
      console.log(tx.data)
      if (tx.status === 200) {
        const res = this.transformT(tx.data)
        this.items = res
      }
    }
  },
  async beforeMount() {
    this.user = getUserData()
    await this.getActiveBank()
    await this.getTransactions()
    this.member = await this.getMemberInfo()
  }
}
</script>

<style></style>
